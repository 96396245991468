











































































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { GET_EMAIL_TEMPLATES } from "@/store/modules/recruiter/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";

import DataNotFound from "@/components/shared/DataNotFound.vue";
import RefreshButton from "@/components/shared/RefreshButton.vue";
import { EmailTemplatesListingHeaderOptions } from "@/interfaces/settings/account";
import { get_color_by_name } from "@/utils/colors";

export default Vue.extend({
  name: "EmailTemplates",
  components: {
    DataNotFound,
    RefreshButton
  },
  data() {
    return {
      loading: false, // Loading state of the page
      data_error: false, // Error state of the page
      table_headers: [
        {
          title: this.$t("email_templates.listing.col-0").toString(),
          cols: 1,
          sortable: true,
          direction: "asc"
        },
        {
          title: this.$t("email_templates.listing.col-1").toString(),
          cols: 4,
          sortable: true,
          direction: "asc"
        },
        {
          title: this.$t("email_templates.listing.col-2").toString(),
          cols: 5
        },
        // { title: this.$t("email_templates.listing.col-3").toString(), cols: 2 },
        {
          title: this.$t("email_templates.listing.col-4").toString(),
          cols: 2
        }
      ] as EmailTemplatesListingHeaderOptions[],
      status: ""
    };
  },
  watch: {
    get_site_direction() {
      this.updateTableHeaders();
    }
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("recruiter", {
      get_email_templates: GET_EMAIL_TEMPLATES
    }),
    not_found_error(): string {
      return this.$t("errors.email-templates-not-found").toString();
    }
  },
  async mounted() {
    await this.fetch_email_templates(); // Fetch templates on component mount
  },
  methods: {
    ...mapActions("recruiter", {
      fetch_email_templates_listing: GET_EMAIL_TEMPLATES
    }),
    truncateName(name: string) {
      const maxLength = 20;
      return name.length > maxLength ? name.slice(0, maxLength) + "..." : name;
    },
    async fetch_email_templates() {
      try {
        this.loading = true;
        await this.fetch_email_templates_listing();
      } catch (error) {
        this.data_error = true;
        console.error("Failed to fetch email templates:", error);
      } finally {
        this.loading = false;
      }
    },
    updateTableHeaders() {
      this.table_headers = [
        {
          title: this.$t("email_templates.listing.col-0").toString(),
          cols: 1
        },
        {
          title: this.$t("email_templates.listing.col-1").toString(),
          cols: 4
        },
        {
          title: this.$t("email_templates.listing.col-2").toString(),
          cols: 3
        },
        { title: this.$t("email_templates.listing.col-3").toString(), cols: 2 },
        { title: this.$t("email_templates.listing.col-4").toString(), cols: 2 }
      ];
    },
    transform_view_icon(icon: HTMLElement): HTMLElement {
      const color = get_color_by_name("success");
      Array.from(icon.children).forEach((node) =>
        node.setAttribute("stroke", color)
      );
      return icon;
    }
  }
});
